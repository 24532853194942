
#layout {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    min-width: 250px;
    font-size: .8rem;;
    overflow: none;
    background-color: #fff;
}

#layout footer {
    width: 100%;
    margin: 0;
    padding: 8px;
    background-color: #222;
    display: flex;
    justify-content: center;
    gap: 1em;
}

#layout footer a {
    font-size: 12px;
    color: #fff;
    text-decoration: none;
}
#layout footer a:hover {
    text-decoration: underline;
}
