
.map-marker {
    min-width: 10px;
    height: 20px;
    border-radius: 10px;
    padding: 0 5px;
    background-color: black;
    color: white;
    text-align: center;
    font: bold 12px/20px Verdana;
}

.map-marker.active {
    background-color: #d72305;
}

.map-marker-start, .map-marker-end {
    background-color: #288732;
}

.my-leaflet-marker .map-marker {
    transform: translate(-50%, -50%);
}
