
.map-editor {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
}

.map-editor .leaflet-container {
    flex-grow: 1;
}

.map-editor .plot-elevation {
    flex-grow: 0;
    height: 150px;
}

.map-buttons {
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.map-editor .plot-elevation {
    margin: 0;
    padding: 8px;
    background-color: #eee;
}

.map-popup-button {
    display: block;
    width: 100%;
    margin: 3px 0;
}
